import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['newDataset']

  showNewDataset() {
    // @ts-ignore
    this.newDatasetTarget.style.display = 'block'
  }

  hideNewDataset() {
    // @ts-ignore
    this.newDatasetTarget.style.display = 'none'
  }
}
