import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['fileInput', 'submitButton']

  declare readonly fileInputTarget: HTMLInputElement
  declare readonly submitButtonTarget: HTMLButtonElement

  // Size limits in bytes
  private readonly MP3_SIZE_LIMIT = 150 * 1024 * 1024 // 150MB
  private readonly WAV_SIZE_LIMIT = 500 * 1024 * 1024 // 500MB

  validateFile() {
    const file = this.fileInputTarget.files?.[0]
    if (!file) {
      this.submitButtonTarget.disabled = true
      return
    }

    const allowedTypes = ['audio/wav', 'audio/mp3', 'audio/mpeg']
    if (!allowedTypes.includes(file.type)) {
      alert('Please upload a WAV or MP3 file only.')
      this.fileInputTarget.value = ''
      this.submitButtonTarget.disabled = true
      return
    }

    // Check file size based on type
    const isWav = file.type === 'audio/wav'
    const sizeLimit = isWav ? this.WAV_SIZE_LIMIT : this.MP3_SIZE_LIMIT
    const sizeLimitMB = isWav ? 500 : 150

    if (file.size > sizeLimit) {
      alert(`File size exceeds the limit of ${sizeLimitMB}MB for ${isWav ? 'WAV' : 'MP3'} files.`)
      this.fileInputTarget.value = ''
      this.submitButtonTarget.disabled = true
      return
    }

    this.submitButtonTarget.disabled = false
  }
}
